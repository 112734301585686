@import url(https://cdn.syncfusion.com/ej2/material.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Jost", 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: #24282a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.enlace_desactivado {
  pointer-events: none;
  cursor: default;
}

.div-negro{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.div-negro-invisible{
  display: none;
}

.invisible{
  display: none;
}

.visible{
  display: block;
}

.titulo_1 {
  font-size: 45px;
  margin: 0;
}
.titulo_2 {
  font-size: 40px;
  font-family: "Paytone One",sans-serif;
  color: #a51d39;
  margin: -10px 0;
}


@media only screen and (min-width:200px) and (max-width:550px){}

@media only screen and (min-width:551px) and (max-width:850px){}

@media only screen and (min-width:851px) and (max-width:1024px){}

@media only screen and (min-width:1025px){}
